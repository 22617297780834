import React from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import { RESPONSIVE_STYLES, WRAPPER_STYLES, RESPONSIVE_PADDING } from '../../../../AppConst'
import { SectionTitle } from '../../../common/SectionTitle'
import { ReadMore } from '../../../common/ReadMore'

import { ServiceCard } from './ServiceCard'
import { EngineerTrainingCard } from './EngineerTrainingCard'
import { ServiceTitle } from './ServiceTitle'

import customer01EC from "../../../../images/service/top_01ec_to_c.png"
import corporate01EC from "../../../../images/service/top_01ec_to_b.png"
import otsutome from "../../../../images/service/top_service_otsutome.png"
import nextCommunication from "../../../../images/service/top_service_next_communication.png"
import teamLive from "../../../../images/service/top_service_teamlive.png"

const ENGINEER_TRAININGS = [
  {
    label: '個人向け',
    img: customer01EC,
    alt: '0→1 ENGINEER CAMP（ゼロイチエンジニアキャンプ） 個人向け｜やる気を掘り起こすプログラミングスクール',
    path: '/lp/01engineer-camp-c/',
    className: 'bannerTop01ECtoC',
  },
  {
    label: '法人向け',
    img: corporate01EC,
    alt: '0→1 ENGINEER CAMP（ゼロイチエンジニアキャンプ）｜実務レベルのエンジニアを育てる超実践型エンジニア育成サービス',
    path: '/lp/01engineer-camp/',
    className: 'bannerTop01ECtoB',
  },
]

const CLOUD_SERVICES = [
  {
    img: otsutome,
    alt: '完全無料のWeb勤怠システム オツトメ！',
    path: 'https://www.otsutome.net/',
    className: 'bannerTopOtsutome',
  },
  {
    img: nextCommunication,
    alt: 'チームのパフォーマンスを最大化 NEXT COMMUNICATION',
    path: 'https://nextcommunication.net/',
    className: 'bannerTopNextCommunication',
  },
  {
    img: teamLive,
    alt: 'TEAM LIVE',
    path: 'javascript:void(0)',
    className: 'bannerTopTEAMLIVE',
  },
]

const Node = styled.div`
  width: 100%;
  background-color: rgba(34, 34, 34, 0.95);
  overflow: hidden;
  position: relative;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: calc(64px - 23px) 0 64px;
  }
  ${RESPONSIVE_PADDING}
`

const Wrapper = styled.div`
  ${WRAPPER_STYLES}
  display: flex;
  flex-direction: column;
  padding: 160px 0;
  gap: 80px;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 0;
    gap: 40px;
  }
`

const SectionTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 8px;
  }
`

const Explanation = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #FFFFFF;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1.4rem;
    line-height: 28px;
  }
`

const SectionService = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ServiceCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    gap: 24px;
  }
`

type Props = {
  technextImg: any
  technextSpImg: any
}

export const Services:React.VFC<Props> = (props) => {
  return (
    <Node>
      <Wrapper>
        <SectionTopWrapper>
          <SectionTitle title='service' color={'rgba(255, 255, 255, 1)'} textAlign='left'/>
          <Explanation>
            ネクストライブ株式会社は大阪のHR Techベンチャー企業です。<br />
            エンジニア育成事業「0→1 ENGINEER CAMP」、クラウドサービスの開発・運営、システム開発を行っています。
          </Explanation>
        </SectionTopWrapper>
        <SectionService>
          <ServiceTitle titleJa='エンジニア育成' titleEn='Engineer training' color='#FFFFFF' />
          <ServiceCardWrapper>
            {ENGINEER_TRAININGS.map((elem, index) => {
              return (
                <EngineerTrainingCard
                  key={index}
                  label={elem.label}
                  img={elem.img}
                  alt={elem.alt}
                  path={elem.path}
                  className={elem.className}
                />
              )
            })}
          </ServiceCardWrapper>
        </SectionService>
        <SectionService>
          <ServiceTitle titleJa='クラウドサービス' titleEn='Cloud Service' color='#FFFFFF' />
          <ServiceCardWrapper>
            {CLOUD_SERVICES.map((elem, index) => {
              return (
                <ServiceCard
                  key={index}
                  img={elem.img}
                  alt={elem.alt}
                  path={elem.path}
                  className={elem.className}
                />
              )
            })}
          </ServiceCardWrapper>
        </SectionService>
        <ReadMore url='/service' darkMode={false} text='サービス一覧を見る'/>
      </Wrapper>
    </Node>
  )
}
