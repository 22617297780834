import React, { useEffect } from "react"
import { graphql } from "gatsby"

import { useDispatch } from "react-redux"
import { fetchArticles } from "../store/articles"
import { Index } from "../components/index"

export default function Home({ data }) {
  const { recruitment } = data
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchArticles())
  }, [])
  return (
    <Index
      title={"ネクストライブ株式会社／NEXTRIBE｜大阪のHR Techベンチャー"}
      description={
        "ネクストライブ株式会社は大阪のHR Techベンチャー企業です。クラウドサービスの開発・運営、エンジニア育成事業「0→1 ENGINEER CAMP」、システム開発を行っています。"
      }
      recruitImg={recruitment.childImageSharp.fluid}
    />
  )
}

export const query = graphql`
  query {
    recruitment: file(relativePath: { eq: "images/img_recruit_01.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
