import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { RESPONSIVE_PADDING, RESPONSIVE_STYLES, WRAPPER_STYLES, OWNEDMEDIA_WORDPRESS_URL } from '../../../../AppConst'
import { ReadMore } from '../../../common/ReadMore'
import { LatestNews } from './LatestNews'
import { WPFrontMatter } from '../../../../types/WPFrontMatter'
import { LoadingAPI } from '../../../common/LoadingAPI'
import { ConnectionFailedText } from '../../../common/ConnectionFailedText'

const fetchInformationPickUpPosts = async (setPosts, setIsLoading, setHasError) => {
  setIsLoading(true);

  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/categories?slug=information`)
  .then(response => {return response.json()})
  .then(categoryData => {
    fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&categories=${categoryData[0].id}&per_page=3`)
    .then(response => {return response.json()})
    .then(postsData => {
      const posts: WPFrontMatter[] = [];

      postsData.map((data, i) => {
        const postData: WPFrontMatter = {
          title: data.title.rendered,
          description: "",
          date: data.date,
          category: data._embedded["wp:term"][0][0].slug,
          author: data._embedded.author[0].name,
          thumbnail: data._embedded["wp:featuredmedia"][0].source_url,
          slug: `${data._embedded.author[0].slug}/${data.slug}`
        }
        posts.push(postData);
      })

      setPosts(posts);
      setIsLoading(false);
    })
    .catch((error) => {
      setHasError(true);
    });
  })
  .catch((error) => {
    setHasError(true);
  });
};

const Node = styled.div`
  background-color: #E9E9E9;
  padding: 160px 0;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 64px 0;
  }
  ${RESPONSIVE_PADDING}
`

const Wrapper = styled.div`
  ${WRAPPER_STYLES}
  display: flex;
  justify-content: space-between;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-direction: column;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-bottom: 40px;
  }
`

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 72px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #222222;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    justify-content: center;
  }
`

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 800px;
`

const ReadMoreWrapper = styled.div`
  text-align: center;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: none;
  }
`
const ReadMoreSpWrapper = styled.div`
  display: none;
  margin-top: 40px;
  text-align: center;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: block;
  }
`

export const News:React.VFC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [posts, setPosts] = useState<WPFrontMatter[]>([]);

  useEffect(() => {
    fetchInformationPickUpPosts(setPosts, setIsLoading, setHasError);
  }, [])

  return (
    <Node>
      { hasError ? <ConnectionFailedText /> :
          isLoading ? <LoadingAPI /> :
            <Wrapper>
              <Header>
                <Title>news</Title>
                <ReadMoreWrapper>
                  <ReadMore url='/information' darkMode={true} text='お知らせ一覧を見る' />
                </ReadMoreWrapper>
              </Header>
              <NewsWrapper>
                {posts.map((post, i) => {
                  return (
                    <LatestNews
                      key={i}
                      frontMatter={post}
                    />
                  )
                })}
              </NewsWrapper>
              <ReadMoreSpWrapper>
                <ReadMore url='/information' darkMode={true} text='お知らせ一覧を見る' />
              </ReadMoreSpWrapper>
            </Wrapper>
      }
    </Node>
  )
}