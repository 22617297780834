import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { RESPONSIVE_STYLES } from '../../../../AppConst'
import { WPFrontMatter } from '../../../../types/WPFrontMatter'
import { formateDate } from '../../../../utlis/formatDate'

const StyledLink = styled(Link)`
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 0px 0px 16px;
  border-bottom: 1px solid #222222;
  text-decoration: none;
  & img {
    transition: 0.5s!important;
    -webkit-transform: translateX(0%) translateY(0%) scale(1.0);
    -moz-transform: translateX(0%) translateY(0%) scale(1.0);
    -o-transform: translateX(0%) translateY(0%) scale(1.0);
    transform: translateX(0%) translateY(0%) scale(1.0);
  }
  &:hover {
    opacity: .7;
    h3 {
      text-decoration: underline;
    }
  }
`

const ImgWrapper = styled.div`
  width: 100%;
  max-width: 108px;
  z-index: 1;
  & img {
    width: 100%;
    transition: 0.5s!important;
    z-index: 1;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    max-width: none;
  }
`

const Date = styled.span`
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7A7A7A;
`

const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #222222;
`

type Props = {
  frontMatter: WPFrontMatter
}

export const LatestNews:React.VFC<Props> = ({ frontMatter }) => {
  return (
    <StyledLink to={`/${frontMatter.slug}`}>
      <ImgWrapper className='image-wrapper'>
        <img src={frontMatter.thumbnail} className='thumbnail' />
        {/* <Img fluid={thumbnail.childImageSharp.fluid} alt={title} /> */}
      </ImgWrapper>
      <Wrapper>
        <Date>{formateDate(frontMatter.date)}</Date>
        <Title>{frontMatter.title}</Title>
      </Wrapper>
    </StyledLink>
  )
}
