import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import videoMP4 from './images/hero-area.mp4';
import videoWEBM from './images/hero-area.webm';

const Wrapper = styled.div`
  width: 100%;
  video {
    display: block;
    width: 100%;
  }
`

export const HeroMovie = () => {
  const refHeroMovie = useRef();

  useEffect(() => {
    if (refHeroMovie.current) {
      refHeroMovie.current.addEventListener('ended', () => {
        refHeroMovie.current.currentTime = 12;
        refHeroMovie.current.play();
      });
    }
  }, []);

  return(
    <video id="first-view-video" ref={refHeroMovie} muted autoPlay playsInline>
      <source src={videoWEBM} type="video/webm" />
      <source src={videoMP4} type="video/mp4" />
    </video>
  )
}

export const Hero: React.VFC = () => {
  return (
    <Wrapper id='first-view'>
      <HeroMovie />
    </Wrapper>
  )
}
