import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { RESPONSIVE_PADDING, RESPONSIVE_STYLES, WRAPPER_STYLES, OWNEDMEDIA_WORDPRESS_URL } from '../../../../AppConst'
import { SectionTitle } from '../../../common/SectionTitle'
import { ReadMore } from '../../../common/ReadMore'
import { ArticleCard } from './ArticleCard'
import { WPFrontMatter } from '../../../../types/WPFrontMatter'
import { LoadingAPI } from '../../../common/LoadingAPI'
import { ConnectionFailedText } from '../../../common/ConnectionFailedText'
import { PickUpBlog } from "../../../../components/blog/PickUpBlog"

const fetchPickUpPosts = async (setPosts, setIsLoading, setHasError) => {
  setIsLoading(true);

  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/categories?slug=information`)
  .then(response => {return response.json()})
  .then(categoryData => {
    fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&categories_exclude=${categoryData[0].id}&per_page=6`)
    .then(response => {return response.json()})
    .then(postsData => {
      const posts: WPFrontMatter[] = [];

      postsData.map((data, i) => {
        const postData: WPFrontMatter = {
          title: data.title.rendered,
          description: "",
          date: data.date,
          category: data._embedded["wp:term"][0][0].slug,
          author: data._embedded.author[0].name,
          thumbnail: data._embedded["wp:featuredmedia"][0].source_url,
          slug: `${data._embedded.author[0].slug}/${data.slug}`
        }
        posts.push(postData);
      })

      setPosts(posts);
      setIsLoading(false);
    })
    .catch((error) => {
      setHasError(true);
    });
  })
  .catch((error) => {
    setHasError(true);
  });
};

const Node = styled.div`
  padding: 160px 0;
  background-color: #FFFFFF;
  min-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 64px 0;
    min-width: unset;
  }
`

export const Blog:React.VFC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [posts, setPosts] = useState<WPFrontMatter[]>([]);

  useEffect(() => {
    fetchPickUpPosts(setPosts, setIsLoading, setHasError);
  }, [])

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          member {
            name
            filename
            pathname
          }
        }
      }
    }
  `)

  const members = site.siteMetadata.member;

  return (
    <Node>
      { hasError ? <ConnectionFailedText /> :
          isLoading ? <LoadingAPI /> :
            <>
              <SectionTitle title='blog' />
              <PickUpBlog></PickUpBlog>
              <ReadMore url='/blog' darkMode={true} text='ブログ一覧を見る' />
            </>
      }
    </Node>
  )
}
