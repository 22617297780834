import React, { useEffect } from "react"

import { Header } from "./Header"
import Footer from "./Footer"
import styled from "styled-components"
import Head from '../../head'
import { Contact } from '../index/contact'
import { RESPONSIVE_STYLES } from "../../AppConst"
import { Loading } from "./Loading"

type ContainerProps = {
  title: string
  description: string
  image: any
  children: React.ReactNode
}

type Props = {
  className?: string
} & ContainerProps

const Component: React.FC<Props> = React.memo(({ className, children, description, image, title }) => {

  useEffect(() => {
    const vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }, [])

  return (
    <div className={className}>
      <Head
        title={title}
        description={description}
        img={image}
      />
      <Loading />
      <Header />
      {children}
      <Contact />
      <Footer />
    </div>
  )
})

const StyledComponent = styled(Component)`
  position: relative;
  width: 100%;
  min-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    min-width: auto;
    padding-bottom: 0;
  }
`

const Conatainer: React.VFC<ContainerProps> = React.memo((props) => {
  return <StyledComponent {...props}/>
})

export { Conatainer as Layout }
