import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "../common/Layout"
import { Hero } from './hero'
import { News } from './v2/news'
import { Blog } from './v2/blog'
import { Recruit } from './v2/recruit'
//import { PickUp } from '../common/PickUp'
import { Services } from './v2/services'
//import { About } from './about'
import { TriangleInCircleButton } from '../common/TriangleInCircleButton'
import { RESPONSIVE_STYLES } from '../../AppConst'
import { ogpUrl } from "../../utlis/ImageUtil"

//import zeroOneImg from '../../images/zero_one_innovation.svg';

type ContainerProps = {
    title: string
    description: string
    recruitImg: any
}

type Props = {
  className?: string
} & ContainerProps

const AnchorWrapper = styled.div`
  position: fixed;
  right: 80px;
  bottom: 80px;
  z-index: 3;
  transition: all 1s ease;
  display: none;
  transform: rotate(180deg);
  z-index: 99;
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    right: 16px;
    bottom: 16px;
  }
  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &.isActive {
    display: block;
    animation: show 1s linear 0s;
  }
`

const Component: React.FC<Props> = React.memo(({ className, title, description }) => {
  const data = useStaticQuery(graphql`
    query {
      topOgpImage: file(relativePath: { eq: "images/top_ogp.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      groupPhotoImg: file(relativePath: { eq: "images/group_photo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1192, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      companyImg: file(relativePath: { eq: "images/about_company.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 640) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      recruitImg: file(relativePath: { eq: "images/about_recruit.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 640) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      membersImg: file(relativePath: { eq: "images/about_members.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 640) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      technextImg: file(relativePath: { eq: "images/01engineercamp_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 2160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      technextSpImg: file(relativePath: { eq: "images/01engineercamp_top_sp.png" }) {
        childImageSharp {
          fluid(maxWidth: 1040) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [isActive, setActive] = useState(false);

  useEffect(() => {
    const anchorElement = document.querySelector("#anchor-top");
    const targetElement = document.querySelector("#first-view");

    if (!anchorElement) return;

    if (!targetElement) {
      return setActive(true);
    }

    const observeHandler = (entries: any) => {
      setActive(!entries[0].isIntersecting)
    };

    if(typeof window !== `undefined`) {
      const targetObserver = new window.IntersectionObserver(observeHandler);
      targetObserver.observe(targetElement);
    }
  }, [])

  // #393 スクロール位置がずれる
  // 原因不明の為暫定対応
  useEffect(() => {
    if(typeof window !== `undefined`) {
      window.scrollTo({ top: 0 })
    }
  }, [])

  const handleClick = () => {
    if(typeof window !== `undefined`) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  return (
    <div className={className} style={{position: 'relative'}}>
      <Layout
        title={title}
        description={description}
        image={ogpUrl(process.env.NODE_ENV, data.topOgpImage.childImageSharp.fluid)}
      >
        <Hero />
        <Services
          technextImg={data.technextImg.childImageSharp.fluid}
          technextSpImg={data.technextSpImg.childImageSharp.fluid}
        />
        <Blog />
        {
        // <Recruit />
        }
        <News />
        {
        // <PickUp />
        // <About
        //   zeroOneImg={zeroOneImg}
        //   groupPhotoImg={data.groupPhotoImg.childImageSharp.fluid}
        //   companyImg={data.companyImg.childImageSharp.fluid}
        //   recruitImg={data.recruitImg.childImageSharp.fluid}
        //   membersImg={data.membersImg.childImageSharp.fluid}
        // />
        }
        <AnchorWrapper id='anchor-top' className={isActive ? 'isActive' : ''}>
          <TriangleInCircleButton direction='top' color='#222' onClick={handleClick} />
        </AnchorWrapper>
      </Layout>
    </div>
  )
})

const StyledComponent = styled(Component)``

const Container = React.memo(function Container({title, description, recruitImg}: ContainerProps){
  return <StyledComponent title={title} description={description} recruitImg={recruitImg} />
})

export { Container as Index }