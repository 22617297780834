import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"
import { RESPONSIVE_STYLES } from '../../../../AppConst'

const Node = styled(Link)`
  width: calc((100% - 80px) / 3);
  transition: .3s;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: calc((100% - 40px) / 2);
  }

  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    width: 100%;
  }

  &:hover {
    opacity: .7;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
`

const StaticImg = styled.img`
  width: 100%;
  height: 100%;
`

type Props = {
  img: any;
  alt: string;
  path: string;
  className: string;
}

export const ServiceCard:React.VFC<Props> = (props) => {
  const blank = props.path === "javascript:void(0)" ? "" : "_blank"
  return (
    <Node to={props.path} target={blank} rel="noopener noreferrer" className={props.className}>
      <ImageWrapper>
        <StaticImg src={props.img} alt={props.alt} />
      </ImageWrapper>
    </Node>
  )
}
