import React from 'react'
import styled from 'styled-components'
import { RESPONSIVE_STYLES } from '../../../../AppConst'

const Node = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleEn = styled.span<{ color?: string }>`
  font-family: Righteous;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ color }) => color === '' ? '#222222' : color};

  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    font-size: 1.4rem;
    line-height: 21px;
  }
`

const TitleJa = styled.span<{ color?: string }>`
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 4.8rem;
  letter-spacing: 0.05em;
  color: ${({ color }) => color === '' ? '#222222' : color};


  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    font-size: 2rem;
    line-height: 30px;
  }
`

type Props = {
  titleJa: string;
  titleEn: string;
  color?: string;
}

export const ServiceTitle:React.VFC<Props> = (props) => {
  return (
    <Node>
      <TitleEn color={props.color}>{props.titleEn}</TitleEn>
      <TitleJa color={props.color}>{props.titleJa}</TitleJa>
    </Node>
  )
}
